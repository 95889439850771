
import { Component, Mixins, Prop } from "vue-property-decorator";
import { VuetifyMixin, StringsMixin, BAIconsMixin, FormRulesMixin, StatusMixin, AthleteApiMixin, AthleteRoutingMixin, AthleteTeamScheduleMixin, HelpInfoMixin } from "@/mixins";
import { ScheduleDetails } from "types";
import { athleteApi } from "@/api/AthleteApi";
import TimePickerDialog from "@/components/calendar/TimePickerDialog.vue";
import AthleteScheduleTable from "./AthleteScheduleTable.vue";

@Component({
	components: {
        TimePickerDialog,
        AthleteScheduleTable,
	}
})
export default class AthleteScheduleCard extends Mixins(VuetifyMixin, StringsMixin, BAIconsMixin, FormRulesMixin, StatusMixin, AthleteApiMixin, AthleteRoutingMixin, AthleteTeamScheduleMixin, HelpInfoMixin) {
    @Prop({ type: Boolean }) flat;
    @Prop({ type: Boolean }) quickEdit;
	@Prop({ type: Boolean }) showEdit;
    @Prop({ type: Boolean }) showPast;
    @Prop({ type: Boolean }) noArchive;

    mounted() {
        this.editScheduleItem = {...this.blankScheduleItem};
    }

    get IsLoading(): boolean {
        return !this.IsAthleteApiReady || !this.teamEventsInitialized;
    }

	blankScheduleItem: ScheduleDetails = {name:'', description:'', date: new Date(), location:'', url:''};
	editTitle: string = "Edit Schedule Event";
	backupScheduleEvent: ScheduleDetails;
	editScheduleItem: ScheduleDetails;
	validScheduleItem: boolean;
	editingSchedule: boolean = false;
	addingScheduleItem: boolean = false;
	editEventDate: Date;
	editEventTime: string;
    earliestTime: Date;
    latestTime: Date;
	beginEditScheduleItem() {
        this.editEventDate = new Date(this.editScheduleItem.date.toDateString());
		this.editEventTime = this.getTime(this.editScheduleItem.date);
        this.SetTimeBoundries();
        this.editingSchedule = true;
	}
	onAddScheduleItem() {
		this.editScheduleItem = {...this.blankScheduleItem};
		this.editTitle = "Add Schedule Event";
		this.addingScheduleItem = true;
		this.beginEditScheduleItem();
	}
    onEditScheduleItem(item: ScheduleDetails) {
		if( !item ) return;
		const index = this.athleteProfile.schedule.findIndex(s => s.date === item.date);
		
		this.editScheduleItem = this.athleteProfile.schedule[index];
        this.editTitle = `Edit ${this.editScheduleItem.name}`;
        this.backupScheduleEvent = {...this.editScheduleItem};
		this.beginEditScheduleItem();
    }
	async SaveUpdatedSchedule() {
        await athleteApi.save(this.athleteProfile);
	}
    async onDeleteScheduleItem(item: ScheduleDetails) {
		if( !item ) return;
		const index = this.athleteProfile.schedule.findIndex(s => s.date === item.date);

        if( !confirm(`Delete ${item.name}? This action cannot be undone`) ) return;
		this.athleteProfile.schedule.splice(index, 1);
		await this.SaveUpdatedSchedule();
    }
    async onCancelEditScheduleItem() {
        this.editScheduleItem = {...this.backupScheduleEvent};
        this.editingSchedule = false;
		this.addingScheduleItem = false;
    }
    async onConfirmEditScheduleItem() {
        if( !(this.$refs.form as HTMLFormElement).validate() ) {
            this.setError( "Please complete all of the required fields" );
            return;
        }

		this.editScheduleItem.date = new Date(this.formatDateSlashesYYYYMMDD(this.editEventDate, true) + ` ${this.editEventTime}`);
		if( this.addingScheduleItem ) this.athleteProfile.schedule.push(this.editScheduleItem);
		await this.SaveUpdatedSchedule();
        this.editingSchedule = false;
		this.addingScheduleItem = false;
    }

    get HasFutureEvents(): boolean {
        return( this.athleteProfile.hasFutureSchedule(this.showArchive) || this.HasFutureTeamEvents );
    }

    showArchive = false;
	get HasArchive(): boolean {
		return !!this.athleteProfile.schedule.find(r => r.archive);
	}

    private SetTimeBoundries(startTime = "6:00 am", endTime = "11:00 pm") {
        this.earliestTime = new Date(`${this.formatDateSlashesYYYYMMDD(this.editEventDate)} ${startTime}`);
        this.latestTime = new Date(`${this.formatDateSlashesYYYYMMDD(this.editEventDate)} ${endTime}`)
    }
}

